import React, { useCallback, useEffect, useState } from 'react'
import { adminLogout } from '../../reducks/admins/operations'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from '../../common'

const Header = () =>
{
  const dispatch = useDispatch()

  const telnumberStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_tel.svg')`
  }

  const mailformStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_mail.svg')`
  }

  return(
    <>
    <footer>
      <div className="subline_1000">
        <div className="contact_infomation_area">
          <div className="telnumber_area">
            <p>お電話によるお問い合わせ</p>
            <p className="telnumber" style={telnumberStyle}>080-5603-3915</p>
            <p>9:00～17:00（土・日・祝日は休み）</p>
          </div>
          <div className="logo_image_area">
          <img src={process.env.PUBLIC_URL + '/images/logo_big_w.svg'}  alt="" />
        </div>
          <div className="mailform_area">
            <p>メールによるお問い合わせ</p>
            <div className="mailform">
              <button className="btn_type_2" style={mailformStyle} onClick={()=>dispatch(push(RouteDir+'/contact'))}>お問い合わせ</button>
            </div>
          </div>
        </div>
      </div>
      <aside className="footer_menu">
          <ul>
            <li onClick={()=>dispatch(push(RouteDir+'/howto'))}>ご利用ガイド</li>
            <li onClick={()=>dispatch(push(RouteDir+'/commercial_transaction'))}>特定商取引法に基づく表記</li>
            <li onClick={()=>dispatch(push(RouteDir+'/privacy_policy'))}>プライバシーポリシー</li>
          </ul>
        </aside>
      <div className="copylight_area">
        <p>Copyright © 2022 東信株式会社 All rights reserved.</p>
      </div>
    </footer>
    </>
  )
}

export default Header